import React from "react"
import { StaticQuery, graphql } from "gatsby"
import MainLayout from "../layout/mainLayout"

class TextPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.wordpressPage,
      seoData: this.props.data.wordpressPage.acf.seo_settings,
    }
  }

  componentDidMount() {
    // Wrap <table> in overflow: auto div
    const tables = document.querySelectorAll("table")

    if (tables) {
      Array.from(tables).forEach(table => {
        const tableWrap = document.createElement("div")
        tableWrap.classList.add("table-wrap")
        table.parentNode.insertBefore(tableWrap, table)
        tableWrap.appendChild(table)
      })
    }
  }

  render() {
    const content = this.state.content

    return (
      <MainLayout
        footerless={false}
        headerTheme="dark"
        cta={true}
        navType="links"
        pageID={this.props.pageContext.id}
        seoData={this.state.seoData}
      >
        <section id="intro" className="intro--plain">
          <div className="grid">
            <div className="row">
              <div className="col lg-8-12 lg-push-left-2-12">
                <h1 className="underlined">{content.title}</h1>
              </div>
            </div>
          </div>
        </section>
        {content.acf.content && (
          <section id="content">
            <div className="grid">
              <div className="row">
                <div className="col lg-8-12 lg-push-left-2-12">
                  <article
                    dangerouslySetInnerHTML={{ __html: content.acf.content }}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </MainLayout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        wordpressPage(wordpress_id: { eq: $id }) {
          title
          template
          wordpress_id
          acf {
            content
            seo_settings {
              meta_title
              meta_description
              meta_title_fb
              meta_description_fb
              meta_title_tw
              meta_description_tw
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <TextPage data={data} pageContext={pageContext} {...props} />
    )}
  />
)
